import React, {useState, useMemo} from "react";
import {Menu, Dropdown, Badge, Avatar, List, Spin} from "antd";
import {useHistory} from "react-router-dom";
import {BellOutlined, CheckCircleOutlined} from "@ant-design/icons";
import moment from "moment";

import Flex from "components/shared-components/Flex";
import FetchInterceptor from "configs/FetchInterceptor";
import {useFetchAPI} from "utils";

const getNotificationBody = ({dataList: list, onNotifPressed}) => {
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable" onClick={() => onNotifPressed(item.id, item.section)}>
          <Flex alignItems="center">
            <div className="pr-3">
              <Avatar icon={<CheckCircleOutlined />} />
            </div>
            <div className="mr-3">
              <span className="d-block font-weight-bold text-dark">{item.title}</span>
              <small className="text-gray-light">{item.body}</small>
            </div>
            <small className="ml-auto">
              {moment(item.updatedAt).format("DD MMMM YY HH:MM")}
              <span className="float-right">{!item.isRead && <Badge status="success" />}</span>
            </small>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  );
};

export const NavNotification = () => {
  let history = useHistory();
  let [visible, setVisible] = useState(false);

  let [limit, setLimit] = useState(5);

  let {
    data: resultData,
    loading: isLoading,
    refetch,
  } = useFetchAPI({
    path: "/v1/notifications",
    method: "get",
    queryParams: {
      limit,
      offset: 0,
    },
  });

  let dataList = useMemo(() => resultData?.data?.rows ?? [], [resultData]);
  let dataCount = useMemo(() => resultData?.data?.count ?? 0, [resultData]);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const onNotifPressed = async (notificationId, section) => {
    await FetchInterceptor.patch(`/v1/notifications/${notificationId}`);
    refetch();
    if (section === "TRANSACTION") {
      history.push("/app/sales/orders");
    }
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        {/* <Button className="text-primary" type="text" onClick={() => setData([])} size="small">
          Clear
        </Button> */}
      </div>
      <Spin spinning={isLoading}>
        <div className="nav-notification-body">
          {getNotificationBody({dataList, onNotifPressed})}
        </div>
      </Spin>
      {dataList.length > 0 ? (
        <div className="nav-notification-footer">
          {/* TODO : Redirect to notification page */}
          <a className="d-block" href="#/" onClick={() => setLimit(limit + 5)}>
            View more
          </a>
        </div>
      ) : null}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={dataList.filter((item) => !item.isRead).length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
