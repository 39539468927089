import React, {useCallback} from "react";
import {Menu, Dropdown, Avatar} from "antd";
import {useHistory} from "react-router-dom";
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import {UserOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";

import {signOut} from "views/auth-views/login/redux";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/",
  },

  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/",
  },
  {
    title: "Billing",
    icon: ShopOutlined,
    path: "/",
  },
  {
    title: "Help Center",
    icon: QuestionCircleOutlined,
    path: "/",
  },
];

export const NavProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {currentUser} = useSelector((state) => ({
    currentUser: state.user.currentUser,
  }));

  const onSignOut = () => {
    dispatch(signOut());
  };

  const navigateToProfile = useCallback(() => {
    history.push("/app/system/profile");
  }, [history]);

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex" onClick={navigateToProfile} style={{cursor: "pointer"}}>
          <Avatar size={40} icon={<UserOutlined />} />
          <div className="pl-3">
            <h4 className="mb-0">{currentUser && currentUser.employee.name}</h4>
            <span className="text-muted">{currentUser && currentUser.role.name}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={menuItem.length + 1} onClick={onSignOut}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar icon={<UserOutlined />} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
